import React, { useEffect, useState } from "react";
import useSWR from "swr";
import { useNavigate } from "react-router-dom";
import { Button, Table } from "react-bootstrap";
import useSelectedRows from "../hooks/useSelectedRows";
import Layout from "../components/Layout";
import Loader from "../components/Loader";
import ErrorMessage from "../components/ErrorMessage";
import TransfersTable from "../components/tables/TransfersTable";
import parseSats from "../utils/parseSats";
import formatBTC from "../utils/formatBTC";
import SearchFilter from "../components/SearchFilter";
import { ToggleButton } from "../components/ToggleButton";
import gpib from "../apis/gpib";

const defaultArray = [];

const PendingTransfersPage = () => {
  const { data, error: fetchError } = useSWR("/transfer/pending");
  const { data: totalCredits, error: fetchTotalCreditsError } = useSWR(
    "/referral/totalCredits"
  );
  const { data: custodialAmount, error: fetchCustodialAmountError } = useSWR(
    "/transfer/custodial"
  );
  const navigate = useNavigate();
  const isLoading =
    !data &&
    !fetchError &&
    !totalCredits &&
    !fetchTotalCreditsError &&
    !custodialAmount &&
    !fetchCustodialAmountError;
  const transfers = data?.transfers || [];
  const [selected, dispatch, selectRowConfig] = useSelectedRows();
  const [isUsingTaproot, setIsUsingTaproot] = useState(false);
  const [walletData, setWalletData] = useState({});
  const [error, setError] = useState(null);
  const isFetchingWallet = !walletData && !error;
  const [transactions, setTransactions] = useState([]);

  useEffect(() => {
    gpib.secure
      .get("/wallet?taproot=" + isUsingTaproot)
      .then(({ data }) => {
        setWalletData(data);
      })
      .catch(error => setError(error));
  }, [isUsingTaproot]);

  useEffect(() => {
    const filterSelected = selected.filter(
      id => data?.transfers && data?.transfers.find(t => t.id === id)
    );
    if (selected.length !== filterSelected.length)
      dispatch({ type: "RESET", payload: filterSelected });
  }, [data, selected, dispatch]);

  const getSelectedTotals = () => {
    const totals = {};
    selected.forEach(id => {
      const row = transfers.find(v => v.id === id);
      if (!row) return;
      if (!totals[row.coin]) return (totals[row.coin] = row.amount);
      totals[row.coin] += row.amount;
    });
    return totals;
  };

  const getFormattedTotals = () => {
    const totals = getSelectedTotals();
    return Object.keys(totals)
      .map(coin => `${totals[coin]} ${coin}`)
      .join(", ");
  };

  const openModal = () => {
    navigate(
      `/pendingtransfers/process/${selected}?total=${getFormattedTotals()}`
    );
  };
  
  const walletBalance = parseSats(walletData?.confirmed || 0);
  const selectedTotal = getSelectedTotals().BTC || 0;
  const balance = walletBalance - selectedTotal;

  const filterTransactions = e => {
    if (e.target.value.toLowerCase() !== "all") {
      const filterTransactions = data.transfers.filter(
        transfer => transfer.type.toLowerCase() === e.target.value
      );
      setTransactions(filterTransactions);
    } else {
      setTransactions(data.transfers);
    }
  };

  useEffect(() => {
    if (data) setTransactions(data.transfers);
  }, [data]);

  return (
    <Layout activeTab="Transfers">
      <div className="container-fluid py-5">
        <div className="d-flex justify-content-between mb-3">
          <div className="d-flex flex-row">
            <div>
              <h1>Pending Transfers</h1>
            </div>
            <div className="ms-5 d-flex justify-content-between align-items-start mt-2">
              <ToggleButton
                defaultChecked={isUsingTaproot}
                onChange={setIsUsingTaproot}
              />
              <span className="ms-3 mt-1">Use Taproot Wallet</span>
            </div>
            <div className="ms-5">
              <input
                className="mt-3"
                name="transactionType"
                value="all"
                type="radio"
                onClick={filterTransactions}
              />
              <label className="ms-2 mt-3">All</label>
            </div>
            <div className="ms-5">
              <input
                className="mt-3"
                name="transactionType"
                value="custodial"
                type="radio"
                onClick={filterTransactions}
              />
              <label className="ms-2 mt-3">Custodial Transactions</label>
            </div>
            <div className="ms-5">
              <input
                className="mt-3"
                name="transactionType"
                value="referral credit"
                type="radio"
                onClick={filterTransactions}
              />
              <label className="ms-2 mt-3">Referral Transactions</label>
            </div>
          </div>
          <Button
            type="button"
            onClick={openModal}
            disabled={!selected.length || balance < 0}
            className="my-2"
          >
            Process selected
          </Button>
        </div>
        <div style={{ position: "relative" }}>
          <Loader loading={isLoading || isFetchingWallet} />
          <ErrorMessage
            error={fetchError || error || fetchTotalCreditsError}
          />
          <Table>
            <tbody>
              <tr>
                <td children="Wallet" />
                <td
                  children={formatBTC(walletBalance)}
                  className="text-right"
                />
              </tr>
              <tr>
                <td children="Selected Total" />
                <td
                  children={formatBTC(selectedTotal)}
                  className="text-right"
                />
              </tr>
              <tr>
                <td children="Balance" />
                <td
                  children={formatBTC(balance)}
                  className={`text-right${balance < 0 ? " text-danger" : ""}`}
                />
              </tr>
              <tr>
                <td children="Referral Credits Accrued Today" />
                <td
                  children={formatBTC(totalCredits)}
                  className="text-danger text-right"
                />
              </tr>
              <tr>
                <td children="Custodial Transactions Total Amount" />
                <td
                  children={formatBTC(custodialAmount)}
                  className="text-right"
                />
              </tr>
            </tbody>
          </Table>
        </div>
        <SearchFilter
          data={transfers || defaultArray}
          setFiltered={setTransactions}
          className="form-control my-4"
          placeholder="Search..."
        />
        <TransfersTable
          transfers={transactions}
          selectRow={selectRowConfig}
          highlightUnverified
          hidden={["tx"]}
        />
      </div>
    </Layout>
  );
};

export default PendingTransfersPage;
