import React from "react";
import useSWR from "swr";
import Layout from "../components/Layout";
import Loader from "../components/Loader";
import ErrorMessage from "../components/ErrorMessage";
import ReferralStrategyTable from "../components/tables/ReferralStrategyTable";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import useSelectedRow from "../hooks/useSelectedRow";
import Card from "../components/Card";
import ReferralSettingTable from "../components/tables/ReferralSettingTable";

const ReferralPage = () => {
  const navigate = useNavigate();
  
  const { data: referralStrategy, error: strategyError } =
    useSWR("/referralStrategy");
  const { data: referralSetting, error: referralSettingError } =
    useSWR("/ReferralSetting");

  const isLoading = !referralStrategy && !strategyError;
  const referralSettingLoading = !referralSetting && !referralSettingError;

  const [selectedStrategyID, , selectStrategyConfig] = useSelectedRow(null);
  const selectedStrategy =
    referralStrategy && referralStrategy.find(d => d.id === selectedStrategyID);

  const [selectedSettingID, , selectSettingConfig] = useSelectedRow(null);
  const selectedSetting =
    referralSetting && referralSetting.find(d => d.id === selectedSettingID);

  return (
    <Layout activeTab="Referral Strategy">
      <div className="container-fluid py-5">
        <Loader loading={isLoading || referralSettingLoading} />
        <div className="mb-3 d-flex justify-content-between">
          <h1 className="mb-3">Referral Strategies</h1>
          <div>
            <Button
              type="button"
              className="me-2"
              onClick={() => navigate("/referral/strategy/add")}
              children="Add Strategy"
            />
            <Button
              type="button"
              className="me-2"
              onClick={() => {
                navigate(`/referral/strategy/edit/${selectedStrategyID}`);
              }}
              children="Edit Strategy"
              disabled={!selectedStrategy}
            />
          </div>
        </div>
        <ErrorMessage error={strategyError} />
        <ReferralStrategyTable
          referralStrategy={referralStrategy}
          selectRow={selectStrategyConfig}
        />
      </div>
      <Card>
        <div className="d-flex justify-content-between">
          <h1>Referral Settings</h1>
          <div>
            <Button
              className="mb-3"
              onClick={() => navigate("/referral/setting/add")}
            >
              <span className="me-2">Add Setting</span>
            </Button>
            <Button
              className="mb-3 ms-3"
              disabled={!selectedSetting}
              onClick={() => {
                navigate(`/referral/setting/edit/${selectedSettingID}`);
              }}
              children="Edit Setting"
            >
              <span className="me-2">Edit Setting</span>
            </Button>
          </div>
        </div>
        <ErrorMessage error={referralSettingError} />
        <ReferralSettingTable
          referralSetting={referralSetting}
          selectRow={selectSettingConfig}
        />
      </Card>
    </Layout>
  );
};

export default ReferralPage;
