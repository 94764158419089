import React from "react";
import useSWR from "swr";
import {
  XYPlot,
  XAxis,
  YAxis,
  VerticalGridLines,
  HorizontalGridLines,
  VerticalBarSeries
} from "react-vis";
import Loader from "../Loader";
import ErrorMessage from "../ErrorMessage";

const TimeSeries = ({ title, series }) => {
  const { data, error } = useSWR(`/stats/${series}`);
  const isLoading = !data && !error;

  const seriesData = data
    ? data.map((d, idx) => ({
        x: data[idx].label,
        y: d.y
      }))
    : [];

  return (
    <div style={{ position: "relative" }}>
      <Loader loading={isLoading} />
      <ErrorMessage error={error} />
      <h2>{title}</h2>
      <XYPlot xType="ordinal" width={1200} height={400} xDistance={100}>
        <VerticalGridLines />
        <HorizontalGridLines />
        <XAxis />
        <YAxis />
        <VerticalBarSeries data={seriesData} />
      </XYPlot>
    </div>
  );
};

export default TimeSeries;
