import { isISO8601 } from "validator";

const validate = values => {
  const requiredMsg = "This field is required";
  const errors = {};
  if (!values.userID) errors.userID = requiredMsg;
  if (!values.amount && String(values.amount) !== "0")
    errors.amount = requiredMsg;
  if (!values.reference) errors.reference = requiredMsg;
  if (!isISO8601(values.created))
    errors.created = "Invalid Format (use ISO 8601 format)";
  if (!isISO8601(values.bankTransactionDate))
    errors.bankTransactionDate = "Invalid Format (use ISO 8601 format)";
  if (!values.fee && String(values.fee) !== "0") errors.fee = requiredMsg;
  if (!values.bankID) errors.bankID = requiredMsg;
  return errors;
};

export default validate;
