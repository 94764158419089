import React from "react";
import useSWR from "swr";
import { useNavigate } from "react-router-dom";
import Loader from "../Loader";
import ErrorMessage from "../ErrorMessage";
import { format as format$ } from "currency-formatter";
import { Button } from "react-bootstrap";

const Exchange = () => {
  const navigate = useNavigate();
  const { data, error } = useSWR("/exchange/balance");
  const isLoading = !data && !error;

  const openModal = () => {
    navigate(
      `/exchange/withdraw/`
    );
  };

  return (
    <div style={{ position: "relative" }}>
      <Loader loading={isLoading} />
      <ErrorMessage error={error} />
      <h2>Exchange</h2>
      <p>AUD: {format$(data?.audBalance, { code: "AUD" })}</p>
      <p>BTC: {data?.btcBalance}</p>
      <Button
        type="button"
        onClick={openModal}
        className="my-2"
      >
        Confirm Withdraw
      </Button>
    </div>
  );
};

export default Exchange;
