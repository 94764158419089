import { isEmail, trim } from "validator";
const validate = values => {
  const errors = {};
  if (!values.to.length) errors.to = "At least one recipient is required";
  if (!values.subject) errors.subject = "Subject is required";
  if (!values.body) errors.body = "Body is required";
  if (!isEmail(trim(values.cc)) && values.cc) errors.cc = "Invalid email";
  if (!isEmail(trim(values.bcc)) && values.bcc) errors.bcc = "Invalid email";

  return errors;
};

export default validate;
