import React from "react";
import Table from "./Table";
import { Link } from "react-router-dom";
const moment = require("moment");

// dataField (key) props (value)
const columnConfig = {
  id: {
    children: "ID",
    width: "4%"
  },
  address: {
    children: "Payment Address",
    width: "20%"
  },
  label: {
    children: "Label",
    width: "8%"
  },
  billerCode: {
    children: "Biller Code",
    width: "10%"
  },
  reference: {
    children: "Ref",
    width: "10%"
  },
  fiat: {
    children: "Amount",
    width: "6%"
  },
  btc: {
    children: "BTC",
    width: "6%"
  },
  btcPaid: {
    children: "BTC Received",
    width: "6%"
  },
  paid: {
    children: "Paid",
    width: "10%",
    dataFormat: cell => moment(cell).format("YYYY-MM-DD HH:mm")
  },
  userID: {
    children: "User ID",
    dataFormat: uid => <Link to={{ pathname: `/user/${uid}` }}>{uid}</Link>
  },
  dueDate: {
    children: "Due Date",
    width: "10%",
    dataFormat: cell => moment(cell).format("YYYY-MM-DD HH:mm")
  }
};

const options = {
  defaultSortName: "id",
  defaultSortOrder: "desc",
  sizePerPage: 25
};

const BillsTable = ({ bills, selectRow, ...props }) => (
  <Table
    data={bills}
    columnConfig={columnConfig}
    selectRow={selectRow}
    keyField="id"
    options={options}
    {...props}
  />
);

export default BillsTable;
