import React, { useEffect } from "react";
import Layout from "../components/Layout";
import useSWR from "swr";
import ErrorMessage from "../components/ErrorMessage";
import Loader from "../components/Loader";
import AddressTable from "../components/tables/AddressTable";
import useSelectedRow from "../hooks/useSelectedRow";
import { ToggleButton } from "../components/ToggleButton";
import { Button } from "react-bootstrap";

const AddressPage = () => {
  const { data, error } = useSWR("/address");
  const isLoading = !data && !error;
  const [custodial, setCustodial] = React.useState(false);
  const [addresses, setAddresses] = React.useState([]);
  const [selectedAddressID, , selectAddressConfig] = useSelectedRow(null);

  useEffect(() => {
    let addresses = data;
    if (custodial) {
      addresses = data.filter(address => address.isCustodial);
    }
    setAddresses(addresses);
  }, [custodial, data]);

  // const handleSelected = () => {
  //   console.log("selectedAddressID", selectedAddressID);

  //   if (!selectedAddressID) {
  //     return false;
  //   }

  //   const selected = addresses.find(address => address.id === selectedAddressID);
  //   console.log("selected", selected);

  //   if (selected && selected.isCustodial) {
  //     console.log("sweeping custodial address");
  //     return true;
  //   }

  //   return false;
  // }

  return (
    <Layout activeTab="Addresses">
      <div className="container-fluid py-5">
        <Loader loading={isLoading} />
        <div className="d-flex justify-content-between align-items-center mb-3">
          <div className="d-flex flex-row">
            <div>
              <h1>Addresses</h1>
            </div>
            <div className="ms-5 d-flex justify-content-between align-items-start mt-2">
              <ToggleButton onChange={setCustodial} defaultChecked={false} />
              <span className="ms-3 mt-1">Custodial Addresses Only</span>
            </div>
          </div>
        </div>
        <div>
          <Button type="button" className="me-2" children="Sweep" disabled={!selectedAddressID}/>
        </div>
        <ErrorMessage error={error} />
        <AddressTable addresses={addresses} selectRow={selectAddressConfig} />
      </div>
    </Layout>
  );
};

export default AddressPage;
