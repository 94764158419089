import React from "react";
import moment from "moment";
import { Formik, Form } from "formik";
import Loader from "../../Loader";
import Input from "../form-inputs/Input";
// import ToggleField from "../form-inputs/ToggleField";
import ErrorMessage from "../../ErrorMessage";
import validate from "./validate";
import useSWR from "swr";

const DepositForm = ({
  initialValues: _inititalValues,
  onSubmit,
  submitText = "Submit",
  disabled = [],
  isEditForm
}) => {
  // Define default values and override with any passed in from props
  const now = moment().seconds(0).milliseconds(0).format("Y-MM-DDTkk:mm:ss");

  const initialValues = {
    userID: "",
    amount: 0,
    reference: "",
    fee: 1.0,
    bankID: 1,
    created: now,
    bankTransactionDate: now,
    rate: 0,
    buyOffExchange: false,
    ..._inititalValues
  };

  const getDisabled = field => disabled.some(v => v === field);
  const { data, error } = useSWR("/exchange/quote?btc=1");

  if (error) {
    return <ErrorMessage error={error} />;
  }

  if (data) {
    initialValues.rate = data;
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validate={validate}
      enableReinitialize
    >
      {({ isSubmitting, errors }) => (
        <Form className="deposit-form">
          {/* <ToggleField label="Buy Off Exchange" name="buyOffExchange" /> */}
          <Input
            label="User ID"
            name="userID"
            disabled={getDisabled("userID")}
          />
          <Input
            label="Amount"
            name="amount"
            disabled={getDisabled("amount")}
          />
          {isEditForm || (
            <Input label="Rate" name="rate" disabled={getDisabled("rate")} />
          )}
          <Input label="Created" name="created" />
          <Input label="Bank Transaction Date" name="bankTransactionDate" />
          <Input
            label="Reference"
            name="reference"
            disabled={getDisabled("reference")}
          />
          <Input label="Fee" name="fee" />
          <Input
            label="Bank ID"
            name="bankID"
            disabled={getDisabled("bankID")}
          />
          <ErrorMessage error={errors.hidden} />
          <button
            className="btn btn-primary btn-block relative d-flex justify-content-center"
            type="submit"
            disabled={isSubmitting}
          >
            <Loader
              loading={isSubmitting}
              noBackground
              noStretch
              light
              diameter="1.4rem"
            />
            <span className="mx-2">{submitText}</span>
          </button>
        </Form>
      )}
    </Formik>
  );
};

export default DepositForm;
