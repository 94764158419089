import React, { useState } from "react";
import { mutate } from "swr";
import { Button, Alert } from "react-bootstrap";
import Layout from "../components/Layout";
import ErrorMessage from "../components/ErrorMessage";
import Loader from "../components/Loader";
import FileUpload from "../components/FileUpload";
import gpib from "../apis/gpib";
import MatchesTable from "../components/tables/MatchesTable";
import MatchedDepositForm from "../components/forms/MatchedDepositsForm";
import Modal from "../components/Modal";
import useSelectedRows from "../hooks/useSelectedRows";
import Quote from "../components/Quote";

const DepositsPage = () => {
  const [isUploading, setUploading] = useState(false);
  const [uploadError, setUploadError] = useState(null);
  const [matches, setMatches] = useState(null);
  const [quote, setQuote] = useState(0);
  const [isMatchModalOpen, setMatchModalOpen] = useState(false);
  const [matchSubmitError, setMatchSubmitError] = useState(null);
  const [selectedMatchIDs, selectedMatchesDispatch, selectMatchConfig] =
    useSelectedRows([]);
  const selectedMatches = selectedMatchIDs.map(id =>
    matches.find(m => m.id === id)
  );

  const processMatches = async (values, actions) => {
    try {
      setMatchSubmitError(null);
      const reqBody = {
        rate: Number(values.rate),
        deposits: values.deposits.map(d => ({
          created: d.created,
          amount: Number(d.amount),
          reference: d.reference,
          bankID: Number(d.bankID),
          fee: Number(d.fee),
          bankTransactionDate: d.bankTransactionDate,
          userID: d.userID
        }))
      };

      await gpib.secure.post("/deposit", reqBody);
      const mutatedValues = values.deposits.map(v => ({ ...v, id: Infinity }));
      mutate("/deposit", d => (d ? [...d, ...mutatedValues] : d));
      selectedMatchesDispatch({ type: "RESET" });
      setMatches(matches.filter(m => !selectedMatchIDs.some(v => v === m.id)));
      setMatchModalOpen(false);
      actions.setSubmitting(false);
    } catch (e) {
      setMatchSubmitError(e);
      actions.setSubmitting(false);
    }
  };

  const onFileUpload = async e => {
    const file = e.target.files[0];
    e.target.value = "";
    if (!file) return;
    try {
      setUploading(true);
      setUploadError(null);
      const fd = new FormData();
      fd.append("attachmentcsv", file, file.name);
      const { data } = await gpib.secure.post("/processcsv", fd, {
        "Content-Type": "multipart/form-data",
        "Cache-Control": "no-store"
      });
      setMatches(data.map((v, id) => ({ ...v, id })));
    } catch (e) {
      setUploadError(e.message);
    }
    setUploading(false);
  };

  const renderContent = () => {
    if (!matches) {
      return (
        <Alert variant="secondary" children="Upload a CSV to find matches" />
      );
    } else if (!matches.length) {
      return (
        <Alert
          variant="secondary"
          children="There are no matches. Upload a new CSV to continue"
        />
      );
    }
    return <MatchesTable matches={matches} selectRow={selectMatchConfig} />;
  };

  const renderUploadContent = () => {
    if (isUploading)
      return (
        <span className="d-flex">
          <Loader loading noBackground noStretch diameter="1rem" light />
          <span className="ms-2">Uploading</span>
        </span>
      );
    return "Upload CSV";
  };
  return (
    <Layout activeTab="Deposits">
      <div className="container-fluid py-5">
        <Loader loading={isUploading} />
        <div className="mb-3 d-flex justify-content-between">
          <h1>Upload CSV</h1>
          <div>
            <FileUpload
              children={renderUploadContent()}
              className="btn btn-primary me-2"
              onChange={onFileUpload}
              disabled={isUploading}
            />
            <Button
              className="me-2"
              type="button"
              children="Process Matches"
              disabled={!selectedMatchIDs.length}
              onClick={() => setMatchModalOpen(true)}
            />
          </div>
        </div>
        <ErrorMessage error={uploadError} />
        <Quote deposits={selectedMatches} setQuote={setQuote} />
        {renderContent()}
        <Modal
          heading="Process Matched Deposits"
          isOpen={isMatchModalOpen}
          onDismiss={() => setMatchModalOpen(false)}
          large
        >
          <MatchedDepositForm
            onSubmit={processMatches}
            onDismiss={() => setMatchModalOpen(false)}
            initialValues={{ deposits: selectedMatches, rate: quote || 0 }}
            submitError={matchSubmitError}
          />
        </Modal>
      </div>
    </Layout>
  );
};

export default DepositsPage;
