import React from "react";
import "./ToggleButton.scss";

export const ToggleButton = ({ defaultChecked, onChange }) => {
  const startState = defaultChecked == null ? true : defaultChecked;
  const [checked, setChecked] = React.useState(startState);
  const handleChange = () => {
    const newValue = !checked;
    setChecked(newValue);
    onChange(newValue);
  };
  return (
    <div className="toggle-field form-group" onClick={handleChange}>
      <div className={`switch ${checked ? "checked" : ""}`}>
        <input type="checkbox" />
        <span className="slider" />
      </div>
    </div>
  );
};
