import React, { useState } from "react";
import Modal from "../Modal";
import Table from "../tables/Table";
import ErrorMessage from "../ErrorMessage";
import SubmitSpinnerButton from "../forms/SubmitSpinnerButton";
import gpib from "../../apis/gpib";

const defaultArray = [];

const columnConfig = {
  userID: {},
  name: {}
};

const parseData = data =>
  data.map(({ userID, firstName, lastName }) => ({
    userID,
    name: [firstName, lastName].filter(v => v).join(" ")
  }));

const ResendRapidIDConfirmModal = ({
  isOpen,
  onDismiss,
  onSuccess,
  selectedIDs,
  to = defaultArray,
  ...props
}) => {
  const [error, setError] = useState(null);
  const [isLoading, setLoading] = useState(false);

  const sendRapidID = async () => {
    try {
      setError(null);
      setLoading(true);
      await gpib.secure.post("/rapidid/resendverify/", selectedIDs);
      setLoading(false);
      onDismiss();
    } catch (e) {
      setError(e);
      setLoading(false);
    }
  };
  return (
    <Modal
      heading="Resend RapidID Verification SMS"
      large
      isOpen={isOpen}
      onDismiss={onDismiss}
      {...props}
    >
      <Table
        columnConfig={columnConfig}
        data={parseData(to)}
        pagination={false}
        headerStyle={{ display: "none" }}
        keyField="userID"
      />
      <ErrorMessage error={error} className="mt-3" />
      <SubmitSpinnerButton
        children="Send Verification"
        onClick={sendRapidID}
        className="mt-3"
        isSubmitting={isLoading}
      />
    </Modal>
  );
};

export default ResendRapidIDConfirmModal;
