import React from "react";
import { Formik, Form } from "formik";
import {
  BootstrapTable as Table,
  TableHeaderColumn as Column
} from "react-bootstrap-table";
import { format as format$ } from "currency-formatter";
import Loader from "../../Loader";
import Input from "../form-inputs/Input";
import ErrorMessage from "../../ErrorMessage";
import validate from "./validate";

const MatchedDepositForm = ({
  initialValues: _inititalValues,
  onSubmit,
  submitError
}) => {
  // Define default values and override with any passed in from props
  const initialValues = {
    rate: "",
    ..._inititalValues
  };
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validate={validate}
      enableReinitialize
    >
      {({ isSubmitting, errors }) => (
        <Form>
          <ErrorMessage error={submitError} className="mb-3" />
          <Input label="Exchange Rate" name="rate" />
          <label>Selected:</label>
          <Table
            data={initialValues.deposits}
            version="4"
            headerStyle={{ display: "none" }}
            tableContainerClass="mb-3"
            tableStyle={{ fontSize: "80%" }}
          >
            <Column dataField="userID" isKey />
            <Column dataField="reference" />
            <Column
              dataField="amount"
              dataFormat={cell => format$(cell, { code: "AUD" })}
              width="15%"
              tdStyle={{ textAlign: "right" }}
            />
          </Table>
          <ErrorMessage error={errors.hidden} />
          <button
            className="btn btn-primary btn-block relative d-flex justify-content-center"
            type="submit"
            disabled={isSubmitting}
          >
            <Loader
              loading={isSubmitting}
              noBackground
              noStretch
              light
              diameter="1.4rem"
            />
            <span className="mx-2">Submit</span>
          </button>
        </Form>
      )}
    </Formik>
  );
};

export default MatchedDepositForm;
