import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import Layout from "../components/Layout";
import useSWR from "swr";
import useSelectedRow from "../hooks/useSelectedRow";
import ErrorMessage from "../components/ErrorMessage";
import Loader from "../components/Loader";
import BillsTable from "../components/tables/BillsTable";
import ConfirmBillPaidModal from "../components/modals/ConfirmBillPaidModal";

const BillsPage = () => {
  const [bills, setBills] = useState([]);
  const { data, error } = useSWR("/bills");
  const isLoading = !data && !error;

  const [selectedID, , selectBillConfig] = useSelectedRow(null);

  const selectedBill = data && data.find(d => d.id === selectedID);
  const [isModalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    if (data) setBills(data);
  }, [data]);

  return (
    <Layout activeTab="Bills">
      <div className="container-fluid py-5">
        <Loader loading={isLoading} />
        <div className="d-flex justify-content-between align-items-end mb-3">
          <h1>Bills</h1>
          <Button
            type="button"
            onClick={() => setModalOpen(true)}
            disabled={!selectedID}
            children="Mark as Paid"
            className="me-2"
          />
        </div>

        <ErrorMessage error={error} />
        <BillsTable bills={bills} selectRow={selectBillConfig} />
      </div>
      <ConfirmBillPaidModal
        isOpen={isModalOpen}
        onDismiss={() => setModalOpen(false)}
        selectedID={selectedID}
        bill={selectedBill}
      />
    </Layout>
  );
};

export default BillsPage;
