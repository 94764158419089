import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import Layout from "../components/Layout";
import useSWR from "swr";
import useSelectedRow from "../hooks/useSelectedRow";
import ErrorMessage from "../components/ErrorMessage";
import Loader from "../components/Loader";
import TransfersTable from "../components/tables/TransfersTable";
import { useNavigate } from "react-router-dom";
import SearchFilter from "../components/SearchFilter";

const defaultArray = [];

const TransfersPage = () => {
  const navigate = useNavigate();
  
  const { data, error } = useSWR("/transfer");
  const [transfers, setTransfers] = useState([]);
  const isLoading = !data && !error;
  const [selectedTransfer, , selectRowConfig] = useSelectedRow(null);

  useEffect(() => {
    if (data) setTransfers(data);
  }, [data]);

  return (
    <Layout activeTab="Transfers">
      <div className="container-fluid py-5">
        <Loader loading={isLoading} />
        <div className="d-flex justify-content-between align-items-end mb-3">
          <h1>Transfers</h1>
          <Button
            type="button"
            onClick={() => navigate(`/transfers/edit/${selectedTransfer}`)}
            disabled={!selectedTransfer}
            children="Edit Transfer"
            className="me-2"
          />
        </div>
        <SearchFilter
          data={data || defaultArray}
          setFiltered={setTransfers}
          className="form-control my-4"
          placeholder="Search..."
        />
        <ErrorMessage error={error} />
        <TransfersTable
          transfers={transfers}
          hidden={["email"]}
          selectRow={selectRowConfig}
        />
      </div>
    </Layout>
  );
};

export default TransfersPage;
