import React from "react";
import Layout from "../components/Layout";
import useSWR from "swr";
import ErrorMessage from "../components/ErrorMessage";
import Loader from "../components/Loader";
import TransactionsTable from "../components/tables/TransactionsTable";

const TransactionsPage = () => {
  const { data, error } = useSWR("/wallet/transactions");
  const isLoading = !data && !error;

  return (
    <Layout activeTab="Transactions">
      <div className="container-fluid py-5">
        <Loader loading={isLoading} />
        <div className="d-flex justify-content-between align-items-end mb-3">
          <h1>Transactions</h1>
        </div>
        <ErrorMessage error={error} />
        <TransactionsTable transactions={data} />
      </div>
    </Layout>
  );
};

export default TransactionsPage;
