import React from "react";

const ModalHeader = ({ heading, onDismiss, noExit }) => {
  return (
    <div className={heading ? "modal-header" : "container-fluid p-2"}>
      {heading && <h5 className="modal-title">{heading}</h5>}
      {!noExit && (
        <button type="button" className="btn-close me-1" onClick={onDismiss} />
      )}
    </div>
  );
};
export default ModalHeader;
