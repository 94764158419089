import React, { useState } from "react";
import Modal from "../Modal";
import { Formik, Form } from "formik";
import Input from "../forms/form-inputs/Input";
import Loader from "../Loader";
import gpib from "../../apis/gpib";

const initialValues = {
  receipt: ""
};

const ConfirmForm = ({ initialValues, onSubmit }) => {
  initialValues = { ...initialValues };

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        // validate={validate}
        // enableReinitialize
      >
        <Form className="bills-form">
          <Input label="Receipt" name="receipt" />

          <button
            className="btn btn-primary btn-block relative d-flex justify-content-center"
            type="submit"
            title="Mark as Paid"
            // disabled={isSubmitting}
          >
            <Loader
              // loading={isSubmitting}
              noBackground
              noStretch
              light
              diameter="1.4rem"
            />
            <span className="mx-2">Mark as Paid</span>
          </button>
        </Form>
      </Formik>
    </>
  );
};

const ConfirmBillPaidModal = ({
  isOpen,
  onDismiss,
  onSuccess,
  selectedID,
  bill,
  ...props
}) => {
  const [error, setError] = useState(null);
  const [isLoading, setLoading] = useState(false);

  const markAsPaid = async values => {
    try {
      setError(null);
      setLoading(true);

      await gpib.secure.put(`/bills/paid/${selectedID}`, {
        id: selectedID,
        receipt: values.receipt
      });

      setLoading(false);
      await onDismiss();

      // if (onSuccess) onSuccess();

      // mutate("/user", state =>
      //   state.filter(u => !selectedIDs.find(id => u.userID === id))
      // );
    } catch (e) {
      setError(e);
      setLoading(false);
    }
  };

  return (
    <Modal
      heading="Mark as paid?"
      isOpen={isOpen}
      onDismiss={onDismiss}
      {...props}
    >
      <p>
        Mark {bill?.label} ${bill?.fiat} as paid?
      </p>
      <ConfirmForm
        onSubmit={markAsPaid}
        initialValues={initialValues}
      ></ConfirmForm>
    </Modal>
  );
};

export default ConfirmBillPaidModal;
