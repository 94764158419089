import React from "react";
import useSWR from "swr";
import Layout from "../components/Layout";
import Loader from "../components/Loader";
import ErrorMessage from "../components/ErrorMessage";
import HistoryTable from "../components/tables/HistoryTable";

const HistoryPage = () => {
  const { data, error } = useSWR("/addresshistory");
  const isLoading = !data && !error;

  return (
    <Layout activeTab="Address History">
      <div className="container-fluid py-5">
        <Loader loading={isLoading} />
        <h1 className="mb-3">Address History</h1>
        <ErrorMessage error={error} />
        <HistoryTable history={data} />
      </div>
    </Layout>
  );
};

export default HistoryPage;
