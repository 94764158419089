import React from "react";
import useSWR from "swr";
import ErrorMessage from "../ErrorMessage";
import Loader from "../Loader";

const satToBTC = sat => {
  if (!sat) return 0;
  return sat / 10 ** 8;
};

const Wallet = () => {
  const { data, error } = useSWR("/wallet?taproot=false");
  const { data: taprootData, error: taprootError } = useSWR(
    "/wallet?taproot=true"
  );

  const isLoading = !data && !error && !taprootData && !taprootError;

  return (
    <section style={{ position: "relative" }}>
      <h2>Wallets</h2>
      <ErrorMessage error={error} />
      <ErrorMessage error={taprootError} />
      <Loader loading={isLoading} />
      <p children={`Deposit Address: ${data?.depositAddress || ""}`} />
      <p children={`Confirmed BTC: ${satToBTC(data?.confirmed)}`} />
      <p
        children={`Taproot Deposit Address: ${
          taprootData?.depositAddress || ""
        }`}
      />
      <p
        children={`Taproot Confirmed BTC: ${satToBTC(taprootData?.confirmed)}`}
      />
    </section>
  );
};
export default Wallet;
